import React, { useState, useEffect } from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Checkbox,
  TablePagination,
  Box,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
  Grid,
  CircularProgress,
  Typography,
  Button,
  ButtonGroup,
  Snackbar,
  Alert,
  TextField,
} from "@mui/material";
import { OrderResponseModel } from "../../../models/ordersIntegration/OrderResponseModel";
import { OrderQueryModel } from "../../../models/ordersIntegration/OrderQueryModel";
import { SystemModel } from "../../../models/ordersIntegration/SystemModel";
import {
  getOrders,
  updateOrders,
} from "../../../core/services/ordersIntegration/ordersService";
import { UpdateOrdersRequest } from "../../../models/ordersIntegration/UpdateOrdersRequest";
import { styles } from "./ordersTable.styles";
import { OrdersIntegrationProps } from "../../../models/ordersIntegration/OrdersIntegrationProps";
import ConfirmDialog from "./confirmDialog";
import { useLocation } from "react-router-dom";
import { getSystems } from "../../../core/services/ordersIntegration/systemsService";
import { SortField, SortDirection, TABLE_COLUMNS } from "../ordersTable.constants";
import { DialogProps } from "../../../models/ordersIntegration/DialogProps";
import { OrdersFilter } from "../../../models/ordersIntegration/OrdersFilter";

const APPROVE_CONFIRMATION_TEXT_BULK: string =
  "The orders will be sent to ChartSwap";
const REJECTION_GENERIC_SYSTEM_CONFIRMATION_TEXT_BULK: string =
  "The orders will be sent to MR8";
const REJECTION_COMPETITOR_SYSTEM_CONFIRMATION_TEXT_BULK: string =
  "The orders will be rejected and the integrator will be notified";
const APPROVE_CONFIRMATION_TEXT_SINGLE: string =
  "The order will be sent to ChartSwap";
const REJECTION_GENERIC_SYSTEM_CONFIRMATION_TEXT_SINGLE: string =
  "The order will be sent to MR8";
const REJECTION_COMPETITOR_SYSTEM_CONFIRMATION_TEXT_SINGLE: string =
  "The order will be rejected and the integrator will be notified";

const PROVIDER_ID_LENGTH: number = 18;

const OrdersTable: React.FC<OrdersIntegrationProps> = ({
  isMatched,
  enableBulkActions,
  enableRowActions,
  enableProviderEdit,
}: OrdersIntegrationProps) => {
  // States
  const [systems, setSystems] = useState<SystemModel[]>([]);
  const [orders, setOrders] = useState<OrderResponseModel[]>([]);
  const [selectedOrders, setSelectedOrders] = useState<number[]>([]);
  const [totalCount, setTotalCount] = useState(0);
  const [pageNumber, setPageNumber] = useState(0);
  const [pageSize, setPageSize] = useState(10);
  const [loading, setLoading] = useState(false);
  const [sortField, setSortField] = useState<SortField | undefined>(SortField.OrderId);
  const [sortDirection, setSortDirection] = useState<SortDirection>(SortDirection.Ascending);
  const [dialogProps, setDialogProps] = useState<DialogProps>({
    action: () => Promise.resolve(),
    open: false,
    text: "",
  });
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState("");
  const [snackbarSeverity, setSnackbarSeverity] = useState<"success" | "error">(
    "success"
  );
  const [editingProviders, setEditingProviders] = useState<{
    [key: number]: string;
  }>([]);
  const [providerIdErrors, setProviderIdErrors] = useState<number[]>([]);
  const [filter, setFilter] = useState<OrdersFilter>({
    systemId: 0,
    isMatched: false
  });

  const location = useLocation();

  // Fetch systems
  useEffect(() => {
    const fetchSystems = async () => {
      try {
        const fetchedSystems = await getSystems();
        setSystems(fetchedSystems);
      } catch (error) {
        console.error("Failed to fetch systems", error);
      }
    };
    fetchSystems();
  }, []);

  // Fetch orders when filter changes
  useEffect(() => {
    // Only fetch if integrator is selected
    if (filter.systemId > 0) {
      fetchOrders();
    }
  }, [filter.systemId, pageNumber, pageSize, sortField, sortDirection]);

  useEffect(() => {
    setOrders([]);
    setFilter({
      systemId: 0,
      isMatched: isMatched || false
    });
    setSortField(SortField.OrderId);
    setSortDirection(SortDirection.Ascending);
    setPageNumber(0);
    setPageSize(10);
  }, [location?.pathname]);

  const handleSort = (field: SortField) => {
    if (sortField === field) {
      setSortDirection(prev => prev === SortDirection.Ascending ? SortDirection.Descending : SortDirection.Ascending);
    } else {
      setSortField(field);
      setSortDirection(SortDirection.Ascending);
    }
  };

  const fetchOrders = async () => {
    setLoading(true);
    try {
      // Clear selection when fetching new orders
      setSelectedOrders([]);
      setEditingProviders({});

      const result = await getOrders({
        ...filter,
        pageNumber: pageNumber + 1,
        pageSize,
        sortField,
        sortDirection
      });
      setOrders(result.data);
      setTotalCount(result.totalCount);
    } catch (error) {
      console.error("Failed to fetch orders", error);
      showSnackbar("Failed to fetch orders", "error");
    } finally {
      setLoading(false);
    }
  };

  const handleFilterChange = <K extends keyof OrderQueryModel>(
    key: K,
    value: OrderQueryModel[K]
  ) => {
    setFilter((prev) => ({ ...prev, [key]: value }));
    // Reset pagination and clear selection when filter changes
    setPageNumber(0);
  };

  const validateProviderId = (orderId: number, providerId: string): boolean => {
    if (providerId.length !== PROVIDER_ID_LENGTH) {
      setProviderIdErrors((prev) => [...prev, orderId]);
      return false;
    }

    // Remove error if validation passes
    setProviderIdErrors((prev) => prev.filter((id) => id != orderId));
    return true;
  };

  const handleRowSelect = (orderId: number) => {
    const selectedIndex = selectedOrders.indexOf(orderId);
    let newSelectedOrders: number[] = [];

    if (selectedIndex === -1) {
      // If not selected, add to selection
      newSelectedOrders = [...selectedOrders, orderId];
    } else {
      // If already selected, remove from selection
      newSelectedOrders = selectedOrders.filter((id) => id !== orderId);
    }

    setSelectedOrders(newSelectedOrders);
  };

  const showSnackbar = (message: string, severity: "success" | "error") => {
    setSnackbarMessage(message);
    setSnackbarSeverity(severity);
    setSnackbarOpen(true);
  };

  const handleProviderEdit = (orderId: number, providerId: string) => {
    setEditingProviders((prev) => ({ ...prev, [orderId]: providerId }));

    validateProviderId(orderId, providerId);
  };

  const handleBulkUpdate = async (isApproved: boolean) => {
    if (selectedOrders.length === 0) {
      showSnackbar("No orders selected", "error");
      return;
    }

    setLoading(true);
    try {
      const updateRequest: UpdateOrdersRequest = {
        orders: selectedOrders.map((orderId) => {
          const order = orders.find((o) => o.orderId === orderId);
          const editedProviderId = editingProviders[orderId];

          return {
            orderId: order?.orderId || 0,
            isApproved,
            providerId: editedProviderId || order?.externalProviderId,
          };
        }),
      };

      await updateOrders(updateRequest);

      showSnackbar(
        `${selectedOrders.length} order(s) ${
          isApproved ? "approved" : "rejected"
        }`,
        "success"
      );

      // Refresh orders after update
      fetchOrders();

      // Clear selections
      setSelectedOrders([]);
      setEditingProviders({});
    } catch (error) {
      console.error("Failed to update orders", error);
      showSnackbar("Failed to update orders", "error");
    } finally {
      setLoading(false);
      hideDialog();
    }
  };

  const handleSingleRowUpdate = async (
    order: OrderResponseModel,
    isApproved: boolean
  ) => {
    setLoading(true);
    try {
      const editedProviderId = editingProviders[order.orderId];

      const updateRequest: UpdateOrdersRequest = {
        orders: [
          {
            orderId: order.orderId,
            isApproved,
            providerId: editedProviderId || order.externalProviderId,
          },
        ],
      };

      await updateOrders(updateRequest);

      showSnackbar(
        `Order ${order.uId} ${isApproved ? "approved" : "rejected"}`,
        "success"
      );

      // Refresh orders after update
      fetchOrders();

      // Clear editing state
      setEditingProviders({});
    } catch (error) {
      console.error("Failed to update order", error);
      showSnackbar("Failed to update order", "error");
    } finally {
      setLoading(false);
      hideDialog();
    }
  };

  const hideDialog = () => {
    setDialogProps((prev) => ({ ...prev, open: false }));
  };

  return (
    <Paper sx={styles.paper} elevation={3} style={{ position: "relative" }}>
      {/* Filter Section */}
      <Box p={2}>
        <Grid container spacing={2} alignItems="center">
          {/* Integrator Selection - Mandatory */}
          <Grid item xs={12} md={3}>
            <FormControl fullWidth>
              <InputLabel>Select Integrator *</InputLabel>
              <Select
                data-testid={"select-integrator-input"}
                value={filter.systemId}
                label="Select Integrator *"
                onChange={(e) =>
                  handleFilterChange("systemId", Number(e.target.value))
                }
                required
                disabled={loading}
              >
                {systems.map((integrator) => (
                  <MenuItem
                    key={integrator.systemId}
                    value={integrator.systemId}
                  >
                    {integrator.systemName}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>
        </Grid>
      </Box>

      {/* Bulk Action Buttons */}
      {selectedOrders.length > 0 && enableBulkActions && (
        <ButtonGroup
          sx={styles.actionButtonGroup}
          variant="outlined"
          color="primary"
        >
          <Button
            color="success"
            onClick={() => {
              setDialogProps({
                open: true,
                text: APPROVE_CONFIRMATION_TEXT_BULK,
                action: () => handleBulkUpdate(true),
              });
            }}
            disabled={selectedOrders.some((order) =>
              providerIdErrors.includes(order)
            )}
          >
            Approve ({selectedOrders.length})
          </Button>
          <Button
            color="error"
            onClick={() => {
              setDialogProps({
                open: true,
                text: systems.find((s) => s.systemId == filter.systemId)?.onlyCS
                  ? REJECTION_COMPETITOR_SYSTEM_CONFIRMATION_TEXT_BULK
                  : REJECTION_GENERIC_SYSTEM_CONFIRMATION_TEXT_BULK,
                action: () => handleBulkUpdate(false),
              });
            }}
          >
            Reject ({selectedOrders.length})
          </Button>
        </ButtonGroup>
      )}

      {/* Loading Overlay */}
      {loading && (
        <Box sx={styles.loadingOverlay}>
          <CircularProgress />
        </Box>
      )}

      {/* Table Section */}
      <Box sx={styles.tableWrapper}>
        <TableContainer sx={styles.tableContainer}>
          <Table stickyHeader>
            <TableHead sx={styles.stickyTableHead}>
              <TableRow>
                {enableBulkActions && (
                  <TableCell padding="checkbox">
                    <Checkbox
                      indeterminate={
                        selectedOrders.length > 0 &&
                        selectedOrders.length < orders.length
                      }
                      checked={
                        orders.length > 0 &&
                        selectedOrders.length === orders.length
                      }
                      onChange={() => {
                        setSelectedOrders(
                          selectedOrders.length === orders.length
                            ? []
                            : orders.map((order) => order.orderId)
                        );
                      }}
                    />
                  </TableCell>
                )}
                {TABLE_COLUMNS.map((column) => (
                  <TableCell
                    key={column.field}
                    sx={column.sortable && filter.systemId > 0 && orders.length > 0 ? styles.sortableCell : undefined}
                    onClick={column.sortable && filter.systemId > 0 && orders.length > 0 ? () => handleSort(column.field) : undefined}
                  >
                    {column.label} {column.sortable && filter.systemId > 0 && orders.length > 0 && sortField === column.field && (
                      sortDirection === SortDirection.Ascending ? '↑' : '↓'
                    )}
                  </TableCell>
                ))}
                {enableRowActions && <TableCell>Actions</TableCell>}
              </TableRow>
            </TableHead>
            <TableBody>
              {!loading && filter.systemId > 0 && orders.length === 0 ? (
                <TableRow>
                  <TableCell colSpan={TABLE_COLUMNS.length + (enableBulkActions ? 2 : 1)}>
                    <Box sx={styles.emptyStateContainer}>
                      <Typography variant="h6" color="textSecondary">
                        No Orders Found
                      </Typography>
                      <Typography variant="body2" color="textSecondary">
                        Try to select a different integrator.
                      </Typography>
                    </Box>
                  </TableCell>
                </TableRow>
              ) : (
                orders.map((order) => {
                  const isSelected = selectedOrders.includes(order.orderId);
                  const editedProviderId = editingProviders[order.orderId];
                  const displayProviderId =
                    editedProviderId !== undefined
                      ? editedProviderId
                      : order.externalProviderId;
                  const providerError = providerIdErrors.find(
                    (id) => id == order.orderId
                  );

                  return (
                    <TableRow key={order.orderId} hover selected={isSelected}>
                      {enableBulkActions && (
                        <TableCell padding="checkbox">
                          <Checkbox
                            checked={isSelected}
                            onChange={() => handleRowSelect(order.orderId)}
                          />
                        </TableCell>
                      )}
                      <TableCell>{order.uId}</TableCell>
                      <TableCell>{order.locationName}</TableCell>
                      <TableCell>{order.streetAddress}</TableCell>
                      <TableCell>{order.state}</TableCell>
                      <TableCell>{order.recordType}</TableCell>
                      <TableCell>
                        {order.recordFromDate
                          ? new Date(order.recordFromDate).toLocaleDateString()
                          : ""}
                      </TableCell>
                      <TableCell>
                        {order.recordToDate
                          ? new Date(order.recordToDate).toLocaleDateString()
                          : ""}
                      </TableCell>
                      {enableProviderEdit ? (
                        <TableCell>
                          <TextField
                            variant="standard"
                            inputProps={{
                              maxLength: 18,
                              "data-testid": "provider-id-input",
                            }}
                            value={displayProviderId}
                            onChange={(e) =>
                              handleProviderEdit(order.orderId, e.target.value)
                            }
                            fullWidth
                            placeholder="Enter Provider ID"
                          />
                        </TableCell>
                      ) : (
                        <TableCell>{order.externalProviderId}</TableCell>
                      )}
                      {enableRowActions && (
                        <TableCell>
                          <ButtonGroup size="small" variant="outlined">
                            <Button
                              disabled={
                                !editingProviders[order.orderId] ||
                                !!providerError
                              }
                              color="success"
                              onClick={() => {
                                setDialogProps({
                                  open: true,
                                  text: APPROVE_CONFIRMATION_TEXT_SINGLE,
                                  action: () =>
                                    handleSingleRowUpdate(order, true),
                                });
                              }}
                            >
                              Update
                            </Button>
                            <Button
                              color="error"
                              onClick={() => {
                                setDialogProps({
                                  open: true,
                                  text: systems.find(
                                    (s) => s.systemId == filter.systemId
                                  )?.onlyCS
                                    ? REJECTION_COMPETITOR_SYSTEM_CONFIRMATION_TEXT_SINGLE
                                    : REJECTION_GENERIC_SYSTEM_CONFIRMATION_TEXT_SINGLE,
                                  action: () =>
                                    handleSingleRowUpdate(order, false),
                                });
                              }}
                            >
                              No Matches
                            </Button>
                          </ButtonGroup>
                        </TableCell>
                      )}
                    </TableRow>
                  );
                })
              )}
            </TableBody>
          </Table>
        </TableContainer>
      </Box>
      <TablePagination
        rowsPerPageOptions={[5, 10, 25]}
        component="div"
        count={totalCount}
        rowsPerPage={pageSize || 10}
        page={pageNumber}
        onPageChange={(_, newPage) => {
          setPageNumber(newPage);
          setSelectedOrders([]);
        }}
        onRowsPerPageChange={(event) => {
          setPageSize(parseInt(event.target.value, 10));
          setPageNumber(0);
          setSelectedOrders([]);
        }}
      />

      {/* Snackbar for Notifications */}
      <Snackbar
        open={snackbarOpen}
        autoHideDuration={6000}
        onClose={() => setSnackbarOpen(false)}
        anchorOrigin={{ vertical: "top", horizontal: "right" }}
      >
        <Alert
          onClose={() => setSnackbarOpen(false)}
          severity={snackbarSeverity}
          sx={{ width: "100%" }}
        >
          {snackbarMessage}
        </Alert>
      </Snackbar>
      <ConfirmDialog
        text={dialogProps.text}
        onConfirm={dialogProps.action}
        isOpen={dialogProps.open}
        onClose={hideDialog}
        loading={loading}
      />
    </Paper>
  );
};

export default OrdersTable;
