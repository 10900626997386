import { TableColumn } from "../../models/ordersIntegration/TableColumn";

export enum SortField {
  OrderId = 'orderId',
  LocationName = 'locationName',
  RecordType = 'recordType',
  RecordFromDate = 'recordFromDate',
  RecordToDate = 'recordToDate',
  ExternalProviderId = 'externalProviderId',
  StreetAddress = 'streetAddress',
  State = 'state'
}

export enum SortDirection {
  Ascending = 'asc',
  Descending = 'desc'
}

export const TABLE_COLUMNS: TableColumn[] = [
  { field: SortField.OrderId, label: 'Order ID', sortable: true },
  { field: SortField.LocationName, label: 'Location Name', sortable: true },
  { field: SortField.StreetAddress, label: 'Street Address', sortable: true },
  { field: SortField.State, label: 'State', sortable: true },
  { field: SortField.RecordType, label: 'Record Type', sortable: true },
  { field: SortField.RecordFromDate, label: 'Date From', sortable: true },
  { field: SortField.RecordToDate, label: 'Date To', sortable: true },
  { field: SortField.ExternalProviderId, label: 'Provider ID', sortable: false },
];
