import React, { useState, useEffect, useCallback } from "react";
import {
  Box,
  IconButton,
  TextField,
  Tooltip,
  Alert,
  AlertTitle,
} from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import DeleteIcon from "@mui/icons-material/Delete";
import OrderAgeInterval from "../../../../../../models/filters/orderAgeInterval";
import QueueSettingModel from "../../../../../../models/status/queueSettingModel";
import { getOrderAges } from "../../../../../../core/services/filter/filterService";
import { FiltersEnum } from "../../../../../../models/enums/filtersEnum";
import { useDispatch, useSelector } from "react-redux";
import {
  isFiltersCustomValuesModified,
  hasOrderAgesError,
} from "../../../../../../redux/slices/unsavedChangesDataSlice";
import AppState from "../../../../../../redux/appStore";

interface ValidationError {
  message: string;
  index: number;
}

interface IntervalError {
  from: ValidationError;
  to: ValidationError;
}

export default function OrderAgeIntervals({
  queueSetting,
  intervals,
  setIntervals,
}: {
  readonly queueSetting: QueueSettingModel | undefined;
  readonly intervals: OrderAgeInterval[];
  readonly setIntervals: React.Dispatch<
    React.SetStateAction<OrderAgeInterval[]>
  >;
}) {
  const [intervalValidationErrors, setIntervalValidationErrors] =
    useState<IntervalError>({
      from: {} as ValidationError,
      to: {} as ValidationError,
    });

  const { hasOrderAgesError: hasError } = useSelector((state: AppState) => ({
    hasOrderAgesError: state.unsavedChangesDataState.hasOrderAgesError,
  }));

  const dispatch = useDispatch();

  const validateIntervals = (newIntervals: OrderAgeInterval[]) => {
    dispatch(hasOrderAgesError(false));
    setIntervalValidationErrors({
      from: {} as ValidationError,
      to: {} as ValidationError,
    });

    for (let index = 0; index < newIntervals.length; index++) {
      const interval = newIntervals[index];
      const prevTo = index > 0 ? newIntervals[index - 1].to : -1;

      if (interval.from === null) {
        setIntervalValidationErrors({
          from: { index, message: `Must not be empty` },
          to: {} as ValidationError,
        });
        dispatch(hasOrderAgesError(true));
        break;
      }

      if (index != newIntervals.length - 1 && interval.to === null) {
        setIntervalValidationErrors({
          from: {} as ValidationError,
          to: { index, message: `Must not be empty` },
        });
        dispatch(hasOrderAgesError(true));
        break;
      }

      if (
        prevTo !== null &&
        interval.from !== null &&
        +interval.from !== +prevTo + 1
      ) {
        setIntervalValidationErrors({
          from: { index, message: `Must be ${+prevTo + 1}` },
          to: {} as ValidationError,
        });
        dispatch(hasOrderAgesError(true));
        break;
      }

      if (
        interval.from !== null &&
        interval.to !== null &&
        +interval.to <= interval.from
      ) {
        setIntervalValidationErrors({
          from: {} as ValidationError,
          to: { index, message: `Must be greater than ${interval.from}` },
        });
        dispatch(hasOrderAgesError(true));
        break;
      }
    }
  };

  useEffect(() => {
    validateIntervals(intervals);
  }, [intervals]);

  useEffect(() => {
    const fetchOrderAges = async () => {
      if (queueSetting?.filterIds.some((id) => id == FiltersEnum.OrderAge)) {
        let orderAges = await getOrderAges(queueSetting?.statusId);
        setIntervals(orderAges || []);
      }
    };

    fetchOrderAges();
  }, [queueSetting?.statusId]);

  const addInterval = () => {
    dispatch(isFiltersCustomValuesModified(true));
    if (intervals.length === 0) {
      setIntervals([{ from: 0, to: null }]);
    } else {
      setIntervals([...intervals, { from: null, to: null }]);
    }
  };

  const removeInterval = (index: number) => {
    dispatch(isFiltersCustomValuesModified(true));

    if (intervals.length > 1) {
      const newIntervals = intervals.filter((_, i) => i !== index);
      newIntervals[newIntervals.length - 1].to = null;
      setIntervals(newIntervals);
    } else {
      setIntervals([]);
    }
  };

  const updateInterval = useCallback(
    (index: number, field: "from" | "to", value: string) => {
      dispatch(isFiltersCustomValuesModified(true));

      setIntervals((prevIntervals) => {
        const newIntervals = prevIntervals.map((interval, idx) =>
          idx === index ? { ...interval, [field]: parseInt(value) } : interval
        );
        return newIntervals;
      });
    },
    [dispatch]
  );

  return (
    <Box>
      {intervals.map((interval, index) => (
        <Box key={interval.id} display="flex" alignItems="center" mb={6}>
          <Tooltip
            title={
              index === intervalValidationErrors.from.index
                ? intervalValidationErrors.from.message
                : ""
            }
            open={index === intervalValidationErrors.from.index}
          >
            <TextField
              label="From"
              type="number"
              value={interval.from}
              onChange={(e) => updateInterval(index, "from", e.target.value)}
              error={index === intervalValidationErrors.from.index}
              size="small"
              disabled={index === 0}
              inputProps={{
                min: 0,
              }}
              sx={{ width: 100, mr: 2 }}
            />
          </Tooltip>
          <Tooltip
            title={
              index === intervalValidationErrors.to.index
                ? intervalValidationErrors.to.message
                : ""
            }
            open={index === intervalValidationErrors.to.index}
          >
            {index === intervals.length - 1 ? (
              <IconButton
                data-testid="add-interval-button"
                onClick={addInterval}
                color="primary"
                sx={{ width: 100, mr: 1 }}
              >
                <AddIcon />
              </IconButton>
            ) : (
              <TextField
                label="To"
                type="number"
                value={interval.to}
                onChange={(e) => updateInterval(index, "to", e.target.value)}
                error={index === intervalValidationErrors.to?.index}
                size="small"
                disabled={index === intervals.length - 1}
                inputProps={{
                  min: 0,
                }}
                sx={{ width: 100, mr: 1 }}
              />
            )}
          </Tooltip>
          {index === intervals.length - 1 && (
            <IconButton
              data-testid="delete-interval-button"
              onClick={() => removeInterval(index)}
              color="error"
              size="small"
            >
              <DeleteIcon />
            </IconButton>
          )}
        </Box>
      ))}
      <Box mb={2}>
        {hasError && (
          <Alert severity="error">
            <AlertTitle>Kindly correct the errors in the form.</AlertTitle>
          </Alert>
        )}
      </Box>
    </Box>
  );
}
